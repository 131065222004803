@import 'bootstrap_light';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$alert-cell-color: inherit;
$rule-cell-bg: #f5f5f5;

$config-yaml-color: #333;
$config-yaml-bg: #f5f5f5;
$config-yaml-border: #ccc;

$query-stats-color: #71808e;

$metrics-explorer-bg: #efefef;

$clear-time-btn-bg: $white;

$checked-checkbox-color: #286090;

$histogram-chart-axis-color: $gray-700;
$histogram-chart-grid-color: $gray-600;
$histogram-chart-hover-color: $gray-400;

.bootstrap {
  @import './shared';
}
